(function () {
	'use strict';

	angular
		.module('app')
		.config(['$translateProvider', 'calendarOptions', loadConfig])
		.factory('languageLoader', [
			'$q',
			'$timeout',
			'$translate',
			'manageTranslations',
			'calendarOptions',
			'$translationCache',
			languageLoader,
		]);

	function loadConfig($translateProvider, calendarOptions) {
		// Do not edit below this line =========================================================================================
		var language = calendarOptions.urlParams.language || 'auto'; //Leave this set to "auto" we get the specific language code elsewhere.
		var detectLanguage =
			window.navigator.userLanguage || window.navigator.language;

		//Set language to calendarOptions
		calendarOptions.languageWithSub =
			language === 'auto' || !language ? detectLanguage : language;

		// Function to return language, do not edit!
		$translateProvider.determinePreferredLanguage(function () {
			var selectedLanguage;
			var result;
			//We want to get the language code before any "_" or "-"
			selectedLanguage =
				language === 'auto' || !language
					? detectLanguage.split(/[-_]+/)[0]
					: language;
			result = selectedLanguage || 'en';
			return result;
		});

		//Value sanitization strategy. 'escape', or 'sanatize' for highest security
		$translateProvider.useSanitizeValueStrategy('escape');
		//Force loader to always fetch values and not use cache
		$translateProvider.forceAsyncReload(true);

		//Use our custom async loader
		$translateProvider.useLoader('languageLoader');
	}

	function languageLoader(
		$q,
		$timeout,
		$translate,
		manageTranslations,
		calendarOptions,
		$translationCache
	) {
		return function (options) {
			var deferred = $q.defer();
			var loadCustom = calendarOptions.loadCustomLanguage;

			manageTranslations.getTranslations(
				options.key,
				loadCustom,
				onGetTranslations
			);

			function onGetTranslations(languageTranslations) {
				deferred.resolve(languageTranslations);
			}

			return deferred.promise;
		};
	}
})();
